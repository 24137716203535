<template>
  <div>
    <b-card title="Общая информация">
      <b-card-text>
        Тут будет какая то сводная информация
      </b-card-text>
    </b-card>
    <sources-widget :orders="orders" />
    <orders-chart-widget :orders="orders" />
    <b-card
      v-if="process === 'development'"
      title="For Developer"
    >
      <pre class="small">{{ $store.getters['workingMode/permissions'] }}</pre>
      <pre class="small">{{ $store.getters['workingMode/checkPermission']('edit_company2') }}</pre>
    </b-card>
  </div>
</template>

<script>
import { ref } from 'vue'
import {
  BCard,
  BCardText,
} from 'bootstrap-vue'
import { getOrders as getOrdersApi } from '@/services/main-api/shop/orders/orders'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import SourcesWidget from './SourcesWidget.vue'
import OrdersChartWidget from './orders-chart-widget/OrdersChartWidget.vue'

export default {
  name: 'DashboardView',
  components: {
    BCard,
    BCardText,
    SourcesWidget,
    OrdersChartWidget,
  },
  setup() {
    const orders = ref([])

    const getOrders = async () => {
      try {
        const res = await getOrdersApi({
          project: store.getters['workingMode/selected_project_id'],
          perPage: 100,

        })

        orders.value = res.data.data
      } catch (e) {
        orders.value = []
      }
    }

    return {
      getOrders,
      orders,
    }
  },
  data() {
    return {
      process: process.env.NODE_ENV,
    }
  },
  async mounted() {
    this.$store.dispatch('auth/findUser')
    this.getOrders()
  },
}
</script>

<style scoped></style>
