<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>
          Заказов за период
        </b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="mb-2 d-flex">
        <b-button-group>
          <b-button
            v-for="button, index in buttons"
            :key="index"
            :variant="activeButton === button.value ? 'primary' : 'outline-primary'"
            size="sm"
            @click="button.onClick"
          >
            {{ button.title }}
          </b-button>
        </b-button-group>
        <div class="d-flex ml-1">
          <b-form-datepicker
            v-model="datepickerFrom"
            class="datepicker"
            placeholder="От"
            size="sm"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
          <b-form-datepicker
            v-model="datepickerTo"
            class="datepicker"
            placeholder="До"
            size="sm"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </div>
      </div>
      <chart-component
        :key="rerenderCounter"
        :height="250"
        :data="chartData.data"
        :options="chartData.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { ref } from 'vue'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BButton,
  BButtonGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import ChartComponent from './ChartComponent.vue'
import chartDataDefault from './chartData'

export default {
  name: 'OrdersChartWidget',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ChartComponent,
    BButton,
    BButtonGroup,
    BFormDatepicker,
  },
  props: {
    orders: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const activeButton = ref('month')
    const chartData = ref({ ...chartDataDefault })
    const rerenderCounter = ref(0)
    const datepickerFrom = ref('')
    const datepickerTo = ref('')
    const labels = ref([])

    const getDaysArray = (start, end) => {
      const arr = []
      for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).setHours(0, 0, 0, 0))
      }
      return arr
    }

    const getMonthDays = () => {
      const curr = new Date()
      const firstDay = (new Date()).setDate(curr.getDate() - 29)

      return getDaysArray(firstDay, curr)
    }

    const getWeekDays = () => {
      const curr = new Date()

      const firstDay = (new Date()).setDate(curr.getDate() - 6)

      return getDaysArray(firstDay, curr)
    }

    const setChartData = (newLabels, newDatasets) => {
      const newChartData = { ...chartData.value }
      if (newLabels) {
        newChartData.data.labels = newLabels
        labels.value = newLabels
      }
      if (newDatasets) {
        newChartData.data.datasets = [{
          ...chartData.value.data.datasets[0],
          data: newDatasets,
        }]
      }

      chartData.value = newChartData
    }

    const getDatasets = () => {
      const orders = [...props.orders]
      const data = {}
      const datasets = []
      for (let i = 0; i < orders.length; i += 1) {
        const date = orders[i].created_at.split(' ')[0]
        if (!data[date]) data[date] = 1
        else data[date] += 1
      }

      Object.keys(data).forEach(key => {
        const currLabels = [...chartData.value.data.labels]
        const currDate = new Date(key).setHours(0, 0, 0, 0)
        const firstLabel = new Date(currLabels[0]).setHours(0, 0, 0, 0)
        const lastLabel = new Date(currLabels.at(-1)).setHours(0, 0, 0, 0)

        if (currDate >= firstLabel && currDate <= lastLabel) {
          datasets.push({ t: key, y: data[key] })
        }
      })
      return datasets
    }

    const compareDates = (first, second) => {
      const firstDate = new Date(first).setHours(0, 0, 0, 0)
      const secondDate = new Date(second).setHours(0, 0, 0, 0)
      return firstDate === secondDate
    }

    const setActiveButton = (start, end) => {
      const monthDays = getMonthDays()
      const weekDays = getWeekDays()
      if (compareDates(start, monthDays[0]) && compareDates(end, monthDays.at(-1))) {
        activeButton.value = 'month'
      } else if (compareDates(start, weekDays[0]) && compareDates(end, weekDays.at(-1))) {
        activeButton.value = 'week'
      } else {
        activeButton.value = ''
      }
    }

    const buttons = [
      {
        title: 'Месяц',
        value: 'month',
        onClick: () => {
          setChartData(getMonthDays())
          datepickerFrom.value = new Date(getMonthDays()[0])
          datepickerTo.value = new Date(getMonthDays().at(-1))
        },
      },
      {
        title: 'Неделя',
        value: 'week',
        onClick: () => {
          setChartData(getWeekDays())
          datepickerFrom.value = new Date(getWeekDays()[0])
          datepickerTo.value = new Date(getWeekDays().at(-1))
        },
      },
    ]

    return {
      getDaysArray,
      getMonthDays,
      getWeekDays,
      buttons,
      activeButton,
      chartData,
      setChartData,
      rerenderCounter,
      datepickerFrom,
      datepickerTo,
      getDatasets,
      labels,
      setActiveButton,
    }
  },
  watch: {
    chartData() {
      this.rerenderCounter += 1
    },
    datepickerFrom(value) {
      const end = this.datepickerTo || new Date()
      this.setChartData(this.getDaysArray(value, end))
      this.setActiveButton(value, end)
    },
    datepickerTo(value) {
      const start = this.datepickerFrom || new Date(Date.now() - 86400000)
      this.setChartData(this.getDaysArray(start, value))
      this.setActiveButton(start, value)
    },
    orders() {
      this.setChartData(null, this.getDatasets())
    },
    labels() {
      this.setChartData(null, this.getDatasets())
    },
  },
  mounted() {
    this.setChartData(this.getMonthDays())
    this.datepickerFrom = new Date(this.getMonthDays()[0])
    this.datepickerTo = new Date(this.getMonthDays().at(-1))
  },
}
</script>

<style scoped lang="scss">
::v-deep .datepicker {
  label {
    line-height: 2.1 !important;
  }
}

.datepicker {
  min-width: 120px;
  margin-right: 5px;
}
</style>
