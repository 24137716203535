<template>
  <b-card
    title="Заказы по статусам"
    class="wrapper"
  >
    <b-spinner
      v-if="isLoading"
      variant="primary"
      class="loading"
    />
    <b-row v-else>
      <b-col
        v-for="source in sources"
        :key="source.id"
        cols="12"
        md="3"
      >
        <b-card
          no-body
          class="mb-0"
        >
          <b-card-title>{{ source.name }}</b-card-title>
          <div
            v-for="status in statuses"
            :key="status.id"
            class="d-flex justify-content-between"
          >
            <div>
              {{ status.name }}
            </div>
            <div>
              {{ getStatusOrdersNum(source.id, status.id) }}
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref } from 'vue'
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BCardTitle,
} from 'bootstrap-vue'
import { getStatuses as getStatusesApi } from '@/services/main-api/shop/statuses'
import useIntegrations from '@/composables/useIntegrations'
import store from '@/store'

export default {
  name: 'SourcesWidget',
  components: {
    BCard,
    BRow,
    BCol,
    BSpinner,
    BCardTitle,
  },
  props: {
    orders: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const isLoading = ref(true)
    const sources = ref([])
    const statuses = ref([])

    const getStatuses = async () => {
      try {
        const res = await getStatusesApi({
          project: store.getters['workingMode/selected_project_id'],
          perPage: 100,
          orderBy: 'sort_order',
          sortedBy: 'asc',
        })

        statuses.value = res.data.data
      } catch (e) {
        statuses.value = []
      }
    }

    const getStatusOrdersNum = (sourceId, statusId) => (
      props.orders.reduce((summ, item) => {
        if (!sourceId && item.source === 'employee' && item.status_id === statusId) {
          return summ + 1
        }
        if (item.source_id === sourceId && item.status_id === statusId) {
          return summ + 1
        }
        return summ
      }, 0)
    )

    const { getItems: getIntegrations, items: integrations } = useIntegrations()
    return {
      getStatuses,
      statuses,
      getIntegrations,
      integrations,
      getStatusOrdersNum,
      sources,
      isLoading,
    }
  },
  watch: {
    integrations(value) {
      this.sources = [...value, { name: 'Создано сотрудником' }]
    },
  },
  async mounted() {
    await Promise.all([
      this.getStatuses(),
      this.getIntegrations(),
    ])
    this.isLoading = false
  },
}
</script>

<style scoped>
.wrapper {
  min-height: 290px;
}
</style>
